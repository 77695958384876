import React, { PureComponent } from 'react'
import chicago from './images/chicago.png'
import banner from './images/banner.png'
import plato from './images/Plato_flechas.png'
import Swiper from 'react-id-swiper';
import './index.css'
import tenedor from './images/tenedor.png'
import cuchillo from './images/cuchillo.png'
import axios from 'axios'
import color from '@material-ui/core/colors/purple';
import { element } from 'prop-types';
import recetasView from '../recetaView/index'
import timer from '../Resource/iconos/timer.svg'
import Slide from 'react-reveal/Slide'
import history from '../../history'
import Fade from 'react-reveal/Fade'
import { Helmet } from "react-helmet"

import banner2 from '../Home/images/Novedades.png'
import bannerMobile from './images/bannermobile.png'
import novedadesMobile from './images/novedadesMobile.png'

import papa from './images/banner-home/Mayonesas/papa.png'
import papaZoom from './images/banner-home/Mayonesas/papa-zoom.png'
import lechugaZoom from './images/banner-home/Mayonesas/lechuga-zoom.png'
import cebollaZoom from './images/banner-home/Mayonesas/cebolla-zoom.png'
import hamburguesa from './images/banner-home/Mayonesas/amburguesa.png'
import mayonesa from './images/banner-home/Mayonesas/mayonesa.png'
import PropTypes from 'prop-types'



import TeSorprendemos from '../TeSorprendemos/index'
import TeSorprendemosMobile from '../TeSorprendemosMobile/index'

import gelatina from './images/banner-home/Gelatinas/sobre-gelatina.png'
import gelatinacubos from './images/banner-home/Gelatinas/jelatina.png'



import mostaza from './images/banner-home/Mostazas/mostaza.png'
import papa2 from './images/banner-home/Mostazas/papas-2.png'
import papa3 from './images/banner-home/Mostazas/papas-3.png'
import papa1 from './images/banner-home/Mostazas/papas.png'
import papa4 from './images/banner-home/Mostazas/papas-4.png'


import fresazoom from './images/banner-home/Mermeladas/fresa-zoom.png'
import fresa from './images/banner-home/Mermeladas/fresa.png'
import mermeladaflotante from './images/banner-home/Mermeladas/mermelada-flotante.png'
import mermelada from './images/banner-home/Mermeladas/mermelada.png'
import panbase from './images/banner-home/Mermeladas/pan-base.png'
import pancubierta from './images/banner-home/Mermeladas/pan-cubierta.png'



import limon from './images/banner-home/Pimientas/limon.png'
import pimientalimon from './images/banner-home/Pimientas/pimienta-limon.png'
import pimienta from './images/banner-home/Pimientas/pimienta.png'




import manzanillapack from './images/banner-home/Tes/manzanilla-pack.png'
import manzanilla from './images/banner-home/Tes/manzanilla.png'
import hoja from './images/banner-home/Tes/hoja.png'
import hojazoom from './images/banner-home/Tes/hojazoom.png'


import der from '../Resource/iconos/arrow-slide-der.svg'
import isq from '../Resource/iconos/arrow-slide-izq.svg'

import derBlanco from './images/arrow-slide-der.svg'
import isqBlanco from './images/arrow-slide-izq.svg'

import Tilt from 'react-parallax-tilt';

import WOW from 'wowjs'

import mermeladapla from './categorias/mermelada-2-copy.png'
import queso from './categorias/queso.png'
import brocoli from './categorias/brocoli.png'
import papapla from './categorias/papas-3-copy.png'
import jitomate from './categorias/jitomate.png'
import pepino from './categorias/pepinos.png'
import zanahoria from './categorias/zanahoria.png'
import fresapla from './categorias/fresa-3.png'
import mangocopy from './categorias/mango-copy.png'
import limonpla from './categorias/limon-2-copy.png'
import mermeladacopy from './categorias/mermelada-2-copy-2.png'

//promo rayados y tigres
import bannerRay from './images/Banner-promo-Rayados-B.png'
import bannerTig from './images/Banner-promo-Tigres-B.png'
import bannerTigM from './images/Banner-promo-M-Tigres-V4.png'
import bannerRayM from './images/Banner-promo-M-Rayados-V4.png'




//platillos
import platillo1 from './categorias/shutterstock-599939000-copy-10.png'
import platillo2 from './categorias/shutterstock-599939000-copy-9.png'
import platillo3 from './categorias/shutterstock-599939000-copy-4.png'
import platillo4 from './categorias/shutterstock-599939000-copy-3.png'
import atun from './categorias/atun@2x.png'
import sushi from './categorias/suchi.png'
import pelos from './categorias/pelos@2x.png'


import { connect } from 'react-redux'
import { maxWidth } from '@material-ui/system';


//platillos
import avesycarnes from './categorias/1080X1080 HOME-PLATILLOS-CARNES-Y-AVES_50.webp'
import postres from './categorias/1080X1080 HOME-PLATILLOS-POSTRES_50.webp'
import entradas from './categorias/1080X1080 HOME-PLATILLOS-ENTRADAS_50.webp'
import salsas from './categorias/1080X1080 HOME-PLATILLOS-SALSAS_50.webp'
import antojitos from './categorias/1080X1080 HOME-PLATILLOS-ANTOJITOS_50.webp'
import guarnicione from './categorias/1080X1080 HOME-PLATILLOS-GUARNICIONES_50.webp'
import bebidas from './categorias/1080X1080 HOME-PLATILLOS-BEBIDAS_50.webp'
import mariscos from './categorias/1080X1080 HOME-PLATILLOS-MARISCOS_50.webp'
import syp from './categorias/1080X1080 HOME-PLATILLOS-PASTAS_50.webp'



import Parallax from 'parallax-js'
import RecetaView from '../recetaView/index';

class Home extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            recetas: null,
            categorias: null,
            buttonMenu: null,
            initialPositionx: null,
            initialPositiony: null,
            endPositionx: null,
            endPositiony: null,
            adornos: null,
            categoriaTag: [],
            banner: []
        }
        this.swipMovilRef = React.createRef();
        this.swMovil = null;
        this.controlchangesw = false;

    }
    hadleNoneSpan = () => {
        const element = document.getElementsByClassName("span-categoria")
        for (var i = 0; i < element.length; i++) {
            element[i].style.display = "none"
        }

    }
    getCategoriasMenu = () => {
        const buttonMenu = this.props.data.categoriaTags.categoriaTags.map((element, index) => {
            if (index === 0) {
                return (
                    <div data-index-number={element.id} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <button className="button-not-focus button-plato">{element.nombre}</button>
                    </div>
                )
            } else {
                return (
                    <div data-index-number={element.id} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <button className="button-not-focus button-plato-swiper">{element.nombre}</button>
                    </div>
                )
            }

        })
        this.setState({
            buttonMenu: buttonMenu
        })

    }


    getCategorias = () => {
        const categorias = this.props.data.categoriaRecetas.categoriaRecetas.map((element, index) => {

            let platillo = null

            switch (element.nombre) {
                case "Aves y Carnes":
                    platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={avesycarnes}></img>
                    break;
                case "Postres":
                    platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={postres}></img>
                    break;
                case "Entradas":
                    platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={entradas}></img>
                    break;
                case "Mariscos":
                    platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={mariscos}></img>
                    break;
                case "Salsas":
                    platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={salsas}></img>
                    break;
                case "antojitos-mexicanos":
                    platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={antojitos}></img>
                    break;
                case "Sopas y pastas":
                    platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={syp}></img>
                    break;
                case "Guarniciones":
                    platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={guarnicione}></img>
                    break;
                case "Bebidas":
                    platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={bebidas}></img>
                    break;

                default:

            }




            return (
                <Slide left>
                    <div onClick={() => { window.scrollTo(0, 0); history.push(`/recetas/${element.url}`) }} onMouseOver={(e) => { if (e.target.lastChild && e.target.tagName != "SPAN") { e.target.lastChild.style.display = "block"; e.target.lastChild.style.position = "absolute" } }} onMouseLeave={(e) => { this.hadleNoneSpan() }} className="col-3 hover-content">
                        {platillo}
                        <span style={{ display: "none", fontFamily: "AvenirBlack", cursor: "pointer", fontSize: "1vw" }} className=" badge badge-pill badge-danger span-categoria  ">{element.nombre}</span>
                    </div>
                </Slide>
            )

        })



        const categoriasMasMobile = this.props.data.categoriaRecetas.categoriaRecetas.map((element, index) => {
            let varplatillo = null

            switch (element.nombre) {
                case "Aves y Carnes":
                    varplatillo = avesycarnes
                    break;
                case "Postres":
                    varplatillo = postres
                    break;
                case "Entradas":
                    varplatillo = entradas
                    break;
                case "Mariscos":
                    varplatillo = mariscos
                    break;
                case "Salsas":
                    varplatillo = salsas
                    break;
                case "antojitos-mexicanos":
                    varplatillo = antojitos
                    break;
                case "Sopas y pastas":
                    varplatillo = syp
                    break;
                case "Guarniciones":
                    varplatillo = guarnicione
                    break;
                case "Bebidas":
                    varplatillo = bebidas
                    break;

                default:

            }
            return (
                <Slide left>
                    <div className="col-12 hover-content">
                        <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={varplatillo}></img>
                        <span style={{ fontFamily: "AvenirBlack", cursor: "pointer", fontSize: "20px", position: "absolute" }} className=" badge badge-pill badge-danger span-categoria  ">{element.nombre}</span>
                    </div>
                </Slide>
            )
        })



        const categoriasMas = this.props.data.categoriaRecetas.categoriaRecetas.map((element, index) => {
            let varplatillo = null
            switch (element.nombre) {
                case "Aves y Carnes":
                    varplatillo = avesycarnes
                    break;
                case "Postres":
                    varplatillo = postres
                    break;
                case "Entradas":
                    varplatillo = entradas
                    break;
                case "Mariscos":
                    varplatillo = mariscos
                    break;
                case "Salsas":
                    varplatillo = salsas
                    break;
                case "antojitos-mexicanos":
                    varplatillo = antojitos
                    break;
                case "Sopas y pastas":
                    varplatillo = syp
                    break;
                case "Guarniciones":
                    varplatillo = guarnicione
                    break;
                case "Bebidas":
                    varplatillo = bebidas
                    break;

                default:

            }
            return (
                <Slide left>
                    <div onClick={() => { window.scrollTo(0, 0); history.push(`/recetas/${element.url}`) }} onMouseOver={(e) => { if (e.target.lastChild && e.target.tagName != "SPAN") { e.target.lastChild.style.display = "block"; e.target.lastChild.style.position = "absolute" } }} onMouseLeave={(e) => { this.hadleNoneSpan() }} className="col-3 hover-content">
                        <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={varplatillo}></img>
                        <span style={{ display: "none", fontFamily: "AvenirBlack", cursor: "pointer", fontSize: "1vw" }} className=" badge badge-pill badge-danger   span-categoria  ">{element.nombre}</span>
                    </div>
                </Slide>
            )

        })
        this.setState({
            categoriasMas,
            categorias,
            categoriasMasMobile
        })
    }
    getRecetas = () => {
        const recetas = this.props.data.recetas.map((element, index) => {

            return (

                /*<RecetaView imagen={element.imagen}></RecetaView>*/

                <div style={{ width: "33.3%" }} className="card-home">
                    <div style={{ overflow: "hidden" }}>
                        <img className="recetas-anim" style={{ maxWidth: "100%", height: "auto" }} src={element.imagen} />
                    </div>
                    <div style={{ padding: "20px", fontFamily: "Avenir-Medium" }}>
                        <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label><br />
                        <label style={{ fontFamily: "AvenirBook" }} ></label>{element.descripcion}
                        <br /> <br />

                        {/* <div style={{ display: "flex", fontFamily: "AvenirBook" }}>
                            <img src={timer} style={{ width: "20px", height: "20px" }} />{element.tiempo + "  " + element.personas}
                        </div>
                        */}
                    </div>
                </div>
            )

        })
        const recetasMobile = this.props.data.recetas.map((element, index) => {

            return (

                <div className="contain-swip" >

                    <img style={{ width: "93%", height: "auto", marginLeft: "10px" }} src={element.imagen} />

                    <div style={{ padding: "20px", fontFamily: "Avenir-Medium" }}>
                        <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.nombre}</label><br />
                        <label style={{ fontFamily: "AvenirBook" }} ></label>{element.descripcion}
                        <br /> <br />
                        <div style={{ display: "flex", fontFamily: "AvenirBook" }}>
                            <img src={timer} style={{ width: "20px", height: "20px" }} />{element.tiempo + "  " + element.personas}
                        </div>
                    </div>
                </div>
            )

        })
        this.setState({
            recetas,
            recetasMobile
        })

    }
    getRecetasPorCategoria = (id) => {

        const category = this.props.data.categoriaTag.categoriaTags.filter((element, index) => {

            if (element.id == id) {
                return element
            }

        })
        console.log(category)


        this.setState({
            categoriaTag: this.props.data.categoriaTag.categoriaTags.filter((element, index) => {
                return (element.nombre != "Peques")
            }),
            recetas: category[0].recetas.filter((e)=>{return(e.recetas_id && e.recetas_id.estatus ==="published")})
        })



    }

    myAwesomeCallback = () => {


    }
    componentDidMount() {
        this.obtenerRecetas();
    }
    obtenerRecetas = async () => {
        const categoriaResult = await axios.get(process.env.REACT_APP_API_URL_NEW + "/categorias_recetas?fields=*.*.*");
        this.props.dispatch({ type: "OBTENER_RECETAS_POR_CATEGORIA", categoriaRecetas: categoriaResult.data.data });
        const resultTags = await axios.get(process.env.REACT_APP_API_URL_NEW + "/tags?fields=*.*,recetas.recetas_id.*");
        this.props.dispatch({ type: "OBTENER_CATEGORIAS_TAGS", categoriaTags: resultTags.data.data });
        const resultBanner = await axios.get(process.env.REACT_APP_API_URL_NEW + "/Banners_home");
        this.setState({
            banner: resultBanner.data.data
        })
        const category = this.props.data.categoriaTag.categoriaTags.filter((element, index) => {
            if (element.id === 5) {
                return element
            }

        })
        const arraytag = []
        this.props.data.categoriaTag.categoriaTags.map((element, index) => {


            if (element.nombre === "Peques" || element.nombre === "Te puede interesar") {

            } else {
                arraytag.push(element)
            }
        })
        const temp = category[0].recetas.filter((e)=>{return(e.recetas_id != undefined)})
        console.log(temp)
        this.setState({
            categorias: this.props.data.categoriaRecetas.categoriaRecetas,
            categoriaTag: arraytag,
            recetas: temp.filter((e)=>{return(e.recetas_id.estatus ==="published")})
        })


    }
    componentDidUpdate(prevProps) {

        if (prevProps.data.categoriaTags != this.props.data.categoriaTags) {


            new WOW.WOW(
                {
                    callback: function (box) {
                        box.addEventListener('animationend', function (event) {

                            box.classList.remove("fadeInDown")

                            box.classList.add("scale-class")
                        });
                    }


                }
            ).init()
            //this.getRecetas();
            // this.getCategorias();
            this.getCategoriasMenu();
            this.getCategoriasMenuMovil();

            const img = new Image();
            img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
            img.onload = () => this.setState({ dragImg: img, adornos: this.props.data.categorias[0].adornos });


        }
    }

    rotateMobile = (e) => {
        var x = e.touches[0].clientX;
        var y = e.touches[0].clientY;
        this.setState({
            endPositionx: e.touches[0].clientX,
            endPositiony: e.touches[0].clientY
        });


        if (x >= this.state.initialPositionx) {
            const rotacion = e.target.style.transform;
            const number = parseInt(rotacion.replace("rotate(", "").replace("deg)", ""))
            e.target.style.transform = `rotate(${number + 7}deg)`
        } else {
            const rotacion = e.target.style.transform;
            const number = parseInt(rotacion.replace("rotate(", "").replace("deg)", ""))
            e.target.style.transform = `rotate(${number - 7}deg)`
        }

        const button = document.querySelector(".swiperbuttonmovil .buttonContainer.swiper-slide-next button");

        if (button) {
            this.getRecetasPorCategoria(button.parentElement.attributes["data-index-number"].value);
        }



    }
    rotate = (e) => {
        var x = window.event.clientX;
        var y = window.event.clientY;


        if (x >= this.state.initialPositionx) {
            const rotacion = e.target.style.transform;
            const number = parseInt(rotacion.replace("rotate(", "").replace("deg)", ""))
            e.target.style.transform = `rotate(${number + 3}deg)`
        } else {
            const rotacion = e.target.style.transform;
            const number = parseInt(rotacion.replace("rotate(", "").replace("deg)", ""))
            e.target.style.transform = `rotate(${number - 3}deg)`
        }

        const button = document.querySelector(".swiper-button .buttonContainer.swiper-slide-next button");

        if (button) {
            this.getRecetasPorCategoria(button.parentElement.attributes["data-index-number"].value);
        }


        // const swiperContainer = document.getElementsByClassName("swiper-button")[0].children[2].children[0].children

        // for (var i = 0; i < swiperContainer.length; i++) {

        //     swiperContainer[i].children[0].classList.remove("button-plato")

        //     // if (swiperContainer[i].classList[1]) {
        //     //     if (swiperContainer[i].classList[1] === "button-plato") {
        //     //         // swiperContainer[i].children[0].classList.remove("button-plato")

        //     //     }


        //     // }
        // }



    }
    nextswiperHome = () => {
        const swiperContainer = document.getElementsByClassName("swiper-home")[0].children;

        var mySwiper = swiperContainer[0].swiper
        mySwiper.slideNext();

    }
    nextprevHome = () => {
        const swiperContainer = document.getElementsByClassName("swiper-home")[0].children;

        var mySwiper = swiperContainer[1].swiper

        mySwiper.slidePrev();
    }
    nextswiperHome = () => {
        const swiperContainer = document.getElementsByClassName("swiper-home")[0].children;
        var mySwiper = swiperContainer[1].swiper
        mySwiper.slideNext();

    }
    slideprev = () => {
        const swiperContainer = document.getElementsByClassName("swiper-platillo")[0].children;
        var mySwiper = swiperContainer[0].swiper

        if (mySwiper !== null)
            mySwiper.slidePrev();
    }
    slidenext = () => {
        const swiperContainer = document.getElementsByClassName("swiper-platillo")[0].children;
        var mySwiper = swiperContainer[0].swiper

        if (mySwiper !== null)
            mySwiper.slideNext();
    }

    swiperRotate() {
        var x = window.event.clientX;
        const swiperElement = document.getElementsByClassName("swiper-button")[0].children[2].swiper;

        if ((x >= this.state.initialPositionx) && (swiperElement !== null)) {
            swiperElement.slidePrev();
        } else {
            swiperElement.slideNext();
        }

        // this.getRecetasPorCategoria(swiperElement.activeIndex + 5);
    }
    swiperRotateMovil() {

        this.swMovil = this.swipMovilRef.current.children[0].swiper

        var x = this.state.endPositionx;

        if ((x >= this.state.initialPositionx) && (this.swMovil !== null))
            this.swMovil.slideNext();
        else if (this.swMovil !== null)
            this.swMovil.slidePrev();



        // this.getRecetasPorCategoria(this.swMovil.activeIndex + 5);

    }


    render() {


        const paramsspace = {
            slidesPerView: 1,
            spaceBetween: "-4%",
            rebuildOnUpdate: true,

        }
        const params1 = {
            loop: false,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false
            },
            speed: 600,
            parallax: true,
            parallaxEl: {
                el: '.parallax-bg',
                value: '-23%'
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: false
            },
            slidesPerView: 1,




        }
        const paramsRecetasplatillo = {
            loop: true,
            rebuildOnUpdate: true,
            slidesPerView: 3,
            spaceBetween: 0,
            autoplay: {
                delay: 3000,
                disableOnInteraction: true
            },
            on: {

                init: () => {

                },

            }
        }
        const paramsButton = {
            slidesPerView: 3,
            spaceBetween: 0,
            loop: true,
            on: {
                renderBullet: function (index, className) {

                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                },
                slideChangeTransitionStart: (params) => {

                    const items = document.querySelectorAll(".swiper-button .buttonContainer button").forEach(i => {

                        i.classList.remove("button-plato");
                    });

                    const button = document.querySelector(".swiper-button .buttonContainer.swiper-slide-next button");

                    if (button) {
                        button.classList.add("button-plato");
                        // this.getRecetasPorCategoria(button.parentElement.attributes["data-index-number"].value);

                    }


                }
            }
        }
        const paramsButtonMovil = {
            slidesPerView: 3,
            spaceBetween: 0,
            noSwiping: true,
            loop: true,
            on: {
                slideChangeTransitionStart: (params) => {

                    const items = document.querySelectorAll(".swiperbuttonmovil .buttonContainer button").forEach(i => {

                        i.classList.remove("button-plato");
                    });

                    const button = document.querySelector(".swiperbuttonmovil .buttonContainer.swiper-slide-next button");

                    if (button) {
                        button.classList.add("button-plato");
                        // this.getRecetasPorCategoria(button.parentElement.attributes["data-index-number"].value);
                    }

                }
            }

        }

        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>McCORMICK México ¡Póngale lo sabroso!</title>
                    <meta name="description" content="McCORMICK México crea productos con sabores únicos para las familias, colocando en sus despensas mayonesas, especias, tés y más." />

                </Helmet>
                {this.state.banner.length > 0 && <seccion className="home-desk">
                    <div style={{ marginTop: "6px" }}>
                        <div style={{ width: "inherit", position: "relative", paddingLeft: "30px", paddingRight: "30px", height: "586px" }} className="swiper-home">
                            <img onClick={() => { this.nextprevHome() }} src={isqBlanco} style={{ maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "45%", left: "5%", cursor: "pointer" }} />
                            <Swiper {...params1}>

                                {this.state.banner.map((e, i) => {
                                    return (
                                        <div className="scene" style={{ backgroundImage: 'url(https://mccormick-cms-staging.qa.cloudherdez.com/assets/' + e.imagen_fondo + '' }} >
                                            <div id="banner-mayonesas-producto"  >
                                                <img alt={e.alt_imagen_comida} style={{ position: "absolute", top: '20%', width: "22%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + e.imagen_comida} />
                                            </div>
                                            <div style={{ position: "absolute", zIndex: "50", top: "20%", left: "35%" }}>
                                                <img alt={e.alt_imagen_producto} style={{ width: "15vw", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + e.imagen_producto} />
                                            </div>
                                            <div className="info">
                                                <Fade left><label style={{ fontSize: "25px" }}>{e.Titulo}</label><br /></Fade>
                                                <Fade left> <label style={{ fontFamily: "SignPainter", fontSize: "34px" }}>{e.Subtitulo}</label><br /></Fade>
                                                <Fade left> <label style={{ fontSize: "28px" }}>{e.frase}</label><br /></Fade>
                                                <Fade left><label style={{ fontFamily: "AvenirBook", fontSize: "16px" }}>{e.texto}</label><br /></Fade>
                                                <button id="btnRecetasMariscos" onClick={() => { window.scrollTo(0, 0); history.push("/recetas" + e.url_recetas) }} style={{ marginRight: "25px" }} className=" btn btn-carousel">VER RECETAS</button>
                                                <button id="btnProductosMayonesa" onClick={() => { window.scrollTo(0, 0); history.push("/productos" + e.url_productos) }} className=" btn btn-carousel">VER PRODUCTOS</button>
                                            </div>

                                        </div>
                                    )
                                })

                                }

                            </Swiper>
                            <img onClick={() => { this.nextswiperHome() }} src={derBlanco} style={{ maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "45%", right: "5%", cursor: "pointer" }} />
                        </div>


                        <div style={{ width: "100%", marginTop: "50px", padding: "20px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", fontFamily: "AvenirBook" }}>
                            <Fade left>
                                <label style={{ fontSize: "18px", textAlign: "center" }}>Innovamos y evolucionamos día a día para ofrecerte una gran variedad de productos, sabores únicos y <br />
                                    en tendencia, descubre como puedes acompañarlos en tus comidas del día a día ¿Listo?<br /><br /></label>
                            </Fade>
                            <Fade left>
                                <b style={{ fontSize: "28px", fontFamily: "CocogooseProRegular", textAlign: "center" }}>Descubre qué le puedes<br />
                                    preparar hoy a tus peques</b>
                            </Fade>
                            <div className="swiper-button  col-5" style={{}}>
                                <div className="opacity-isq" >
                                </div>
                                <div className="opacity-der" >
                                </div>


                                {(() => {
                                    if (this.state.categoriaTag.length > 0) {
                                        const but = this.state.categoriaTag.map((element, index) => {
                                            return (
                                                <div className="buttonContainer" data-index-number={element.id} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <button className="button-not-focus button-plato-swiper">{element.nombre}</button>
                                                </div>
                                            )
                                    
                                        })
                                        return (
                                            <Swiper {...paramsButton}>
                                                {but}
                                            </Swiper>
                                        )
                                    }
                                })()}
                            </div>
                            {/* <div style={{ height: "570px", width: "100%", position: "absolute" }}>
                                <div style={{ transform: "translate(-50%, -50%)", position: "absolute", left: "16%", top: "15%", width: "97px", height: "97px" }}>
                                    <div className="latidos">
                                        <img className="adorno-1  wow fadeInDown " data-wow-iteration="1" data-wow-offset="90" data-wow-delay=".5s" style={{ maxWidth: "100%", height: "auto" }} src={sushi} />
                                    </div>
                                </div>
                                <div style={{ transform: "translate(-50%, -50%)", position: "absolute", left: "7%", top: "38%", width: "116px", height: "116px" }}>
                                    <div className="latidos">
                                        <img className="adorno-2 wow fadeInDown " data-wow-iteration="1" data-wow-offset="90" data-wow-delay=".75s" style={{ maxWidth: "100%", height: "auto" }} src={pepino} />
                                    </div>
                                </div>
                                <div style={{ transform: "translate(-50%, -50%)", position: "absolute", right: "10%", top: "20%", width: "77px", height: "77px" }}>
                                    <div className="latidos">
                                        <img className="adorno-3 wow fadeInDown " data-wow-iteration="1" data-wow-offset="90" data-wow-delay="1s" style={{ maxWidth: "100%", height: "auto" }} src={atun} />
                                    </div>
                                </div>

                                <div style={{ transform: "translate(-50%, -50%)", position: "absolute", right: "4%", top: "45%", width: "116px", height: "115px" }}>
                                    <div className="latidos">
                                        <img className="adorno-4 wow fadeInDown " data-wow-iteration="1" data-wow-offset="90" data-wow-delay="1.5s" style={{ maxWidth: "100%", height: "auto" }} src={pelos} />
                                    </div>
                                </div>
                            </div> */}
                            <div draggable={false} style={{ display: "flex", width: "60%", justifyContent: "space-evenly", alignItems: "start", marginTop: "10px", position: "relative" }} onDragOver={(e) => e.preventDefault()}>
                                <div className="plato-info">
                                    <b>Gira el plato</b> <br />
                                    y descubre algunas recetas<br />
                                    que puedes preparar.<br />
                                </div>
                                <img draggable={false} src={tenedor} style={{ maxWidth: "100%", height: "auto", marginTop: "25px" }} />
                                <img draggable={false} className="image-plato" onMouseLeave={() => { this.controlchangesw = false }} onMouseUp={() => { this.controlchangesw = false; this.swiperRotate() }} onMouseDown={(e) => { this.controlchangesw = true; this.setState({ initialPositionx: window.event.clientX, initialPositiony: window.event.clientY }) }} onMouseMove={(e) => { if (this.controlchangesw) { this.rotate(e) } }} style={{ maxWidth: "100%", height: "auto", transform: "rotate(4deg)" }} src={plato}></img>
                                <img draggable={false} src={cuchillo} style={{ maxWidth: "100%", height: "auto", marginTop: "25px" }} />
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-250px", flexDirection: "column", background: "#ffffff" }}>
                            <div className="col-9" style={{ width: "100%", display: "flex", flexWrap: "wrap", fontFamily: "Avenir" }}>
                                <img src={isq} onClick={() => { this.slideprev() }} style={{ maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "32%", left: "-10%", cursor: "pointer" }} />
                                {(() => {
                                    var swiperElement=[]
                                    if (this.state.recetas ) {
                                     
                                        swiperElement = <div style={{ width: "inherit" }} className="swiper-platillo">
                                                <Swiper {...paramsRecetasplatillo}>
                                                    {
                                                            this.state.recetas.map((element, index) => {
                                                            return (
                                                                
                                                                <div key={index} onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.recetas_id.url.replace(/ /g, "-").toLowerCase()}`) }} style={{ width: "33.3%" }} className="card-home">
                                                                    <div style={{ overflow: "hidden" }}>
                                                                        <img  alt={element.recetas_id.alt_imagen}  className="recetas-anim" style={{ maxWidth: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS +element.recetas_id.imagen} />
                                                                    </div>
                                                                    <div style={{ padding: "20px", fontFamily: "Avenir-Medium" }}>
                                                                        <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.recetas_id.nombre}</label><br />
                                                                        <label style={{ fontFamily: "AvenirBook" }} ></label>{element.recetas_id.descripcion}
                                                                        <br /> <br />
                                                                    </div>
                                                                </div>
                                                            )
                
                                                        })
                                                    }
                                                </Swiper>
                                            </div>
                                        
                                    }
                                    return swiperElement
                                })()}

                                <img onClick={() => { this.slidenext() }} src={der} style={{ maxWidth: "100%", height: "auto", zIndex: "10", position: "absolute", top: "32%", right: "-10%", cursor: "pointer" }} />
                            </div>
                            <button onClick={(e) => { window.scrollTo(0, 0); history.push("/recetas") }} style={{ margin: "10PX" }} className=" btn btn-carousel menos">VER TODAS</button><br /><br /><br />
                        </div>

                        <TeSorprendemos></TeSorprendemos>

                        <div style={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "end", fontFamily: "avenir", marginTop: "120px", marginBottom: "50px" }}>
                            <div className="col-6" style={{ paddingLeft: "4%" }}>
                                <Fade left> <p style={{ color: "#d41246", fontFamily: "AvenirBlack", fontSize: "22px" }}>RECETAS</p><br /></Fade>

                                <Fade left> <label className="CocogooseProRegular" style={{ fontSize: "42px" }}><b>UN SINFÍN DE</b><br />
                                    <b>RECETAS  PENSADAS </b><br />
                                    <b>PARA TI Y TU FAMILIA </b></label><br /></Fade>

                                <Fade left><label style={{ fontFamily: "AvenirBook", fontSize: "20PX" }}>Para saciar tu antojo, elige entre un sinfín de recetas que tenemos pensadas para consentirlos a ti y a tu familia.</label></Fade>

                            </div>

                            {(() => {
                                if (this.state.categorias) {
                                    const categorias = this.state.categorias.map((element, index) => {
                                        let platillo = null
                                        switch (element.nombre) {
                                            case "Carnes y Aves":
                                                platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={avesycarnes}></img>
                                                break;
                                            case "Postres":
                                                platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={postres}></img>
                                                break;
                                            case "Entradas":
                                                platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={entradas}></img>
                                                break;
                                            case "Mariscos":
                                                platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={mariscos}></img>
                                                break;
                                            case "Salsas":
                                                platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={salsas}></img>
                                                break;
                                            case "Antojitos Mexicanos":
                                                platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={antojitos}></img>
                                                break;
                                            case "Pastas y Sopas":
                                                platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={syp}></img>
                                                break;
                                            case "Guarniciones":
                                                platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={guarnicione}></img>
                                                break;
                                            case "Bebidas":
                                                platillo = <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={bebidas}></img>
                                                break;
                                            default:
                                        }
                                        return (
                                            <Slide left>
                                                <div onClick={() => { window.scrollTo(0, 0); history.push(`/recetas/${element.url.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "-")}`) }} onMouseOver={(e) => { if (e.target.lastChild && e.target.tagName != "SPAN") { e.target.lastChild.style.display = "block"; e.target.lastChild.style.position = "absolute" } }} onMouseLeave={(e) => { this.hadleNoneSpan() }} className="col-3 hover-content">
                                                    {platillo}
                                                    <span style={{ display: "none", fontFamily: "AvenirBlack", cursor: "pointer", fontSize: "1vw" }} className=" badge badge-pill badge-danger span-categoria  ">{element.nombre}</span>
                                                </div>
                                            </Slide>
                                        )

                                    })
                                    return categorias

                                }
                            })()}

                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center",marginBottom:'30px' }}>
                            <button onClick={(e) => { history.push("/recetas"); window.scrollTo(0, 0) }} className=" btn btn-carousel todas-platillos">VER TODAS</button>
                        </div>
                    </div>
                </seccion>}
                <seccion className="home-mobile">
                {this.state.banner.length > 0 &&<Swiper {...params1}>

                        {this.state.banner.map((e, i) => {
                            return (
                                    <div style={{ width: "100%", height: "550px",backgroundImage: 'url(https://mccormick-cms-staging.qa.cloudherdez.com/assets/' + e.imagen_fondo + '' }}>
                                       
                                        <Fade top >
                                            <div style={{ position: "absolute", zIndex: "50", top: "10%", left: "11.5%" }}>
                                                <img alt={e.alt_imagen_comida} style={{ maxWidth: "40%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + e.imagen_comida}  />
                                            </div>
                                        </Fade>
                                        <Fade top >
                                            <div style={{ position: "absolute", zIndex: "50", top: "4%", left: "45%" }}>
                                                <img alt={e.alt_imagen_producto}  style={{ maxWidth: "73%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS + e.imagen_producto}  />
                                            </div>
                                        </Fade>
                                        
                                       

                                        <div style={{ position: "absolute", bottom: "-1%", padding: "23px", fontFamily: "CocogooseProRegular" }}>
                                            <Fade left><label style={{ fontSize: "21px" }}>{e.Titulo}</label><br /></Fade>
                                            <Fade left> <label style={{ fontFamily: "SignPainter", fontSize: "20px" }}>{e.Subtitulo}</label><br /></Fade>
                                            <Fade left> <label style={{ fontSize: "20px" }}>{e.frase}</label><br /></Fade>
                                            <Fade left><label style={{ fontFamily: "AvenirBook", fontSize: "15px" }}>{e.texto}</label><br /></Fade>
                                            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                <button id="btnRecetasMariscos" onClick={() => { window.scrollTo(0, 0); history.push("/recetas"+ e.url_recetas) }} className="btn btn-carousel btnBanner-homeMobile-verRecetas">VER RECETAS</button>
                                                <button id="btnProductosMayonesa" onClick={() => { window.scrollTo(0, 0); history.push("/productos"+ e.url_productos) }} className="btn btn-carousel btnBanner-homeMobile-verProductos">VER PRODUCTOS</button>
                                            </div>

                                        </div>


                                        <img src={bannerMobile} style={{ width: "100%", height: "auto" }}></img>
                                    </div>
                                    )
                                })
                            }
                        
                          
                    </Swiper>}
                    <div style={{ width: "100%", marginTop: "10px", padding: "20px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", fontFamily: "AvenirBook" }}>
                        <Fade left>
                            <label style={{ fontSize: "13px", textAlign: "center" }}>Innovamos y evolucionamos día a día para ofrecerte una gran variedad de productos, sabores únicos y <br />
                                en tendencia, descubre como puedes acompañarlos en tus comidas del día a día ¿Listo?<br /><br /></label>
                        </Fade>
                        <Fade left>
                            <b style={{ fontSize: "15px", fontFamily: "CocogooseProRegular", textAlign: "center" }}>Descubre qué le puedes<br />
                                preparar hoy a tus peques</b>
                        </Fade>
                        <div className="mt-4 col-12" style={{}}>
                            <div className="content-opacity">
                                <div className="opacity-izq-mov" >
                                </div>
                                <div className="opacity-der-mov" >
                                </div>
                            </div>
                            <div className="swiperbuttonmovil" ref={this.swipMovilRef} >

                                {this.state.categoriaTag.length > 0 ?
                                    <Swiper {...paramsButtonMovil}>

                                        {this.state.categoriaTag.map((element, index) => {

                                            return (

                                                <div className="buttonContainer" data-index-number={element.id} style={{ width: "100%", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    {element.nombre === "Te puede interesar" ? null : <button className="button-not-focus button-plato-swiper-mv">{element.nombre}</button>}

                                                </div>
                                            )

                                        })}
                                    </Swiper>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-evenly", overflow: "hidden", alignItems: "start", position: "relative" }} onDragOver={(e) => e.preventDefault()}>
                        <div className="plato-info">
                            <b>Gira el plato</b> y descubre algunas recetas
                            que puedes preparar.<br />
                        </div>
                        <img draggable={true} className="image-plato" onTouchEnd={(e) => { this.swiperRotateMovil() }} ondragover={(e) => { e.preventDefault(); e.dataTransfer.setDragImage(<p></p>, 0, 0); }} onTouchStart={(e) => { this.setState({ initialPositionx: e.touches[0].clientX, initialPositiony: e.touches[0].clientY }); }} onTouchMove={(e) => { e.preventDefault(); this.rotateMobile(e) }} style={{ maxWidth: "100%", height: "auto", transform: "rotate(0deg)", touchAction: "none" }} src={plato}></img>
                    </div>
                    <div style={{ width: "100%", background: "#ffffff", display: "flex", justifyContent: "center" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-190px", flexDirection: "column", background: "#ffffff", width: "100%" }}>
                            <div style={{ width: "100%", display: "flex", flexWrap: "wrap", fontFamily: "Avenir" }}>
                                {(() => {
                                    if (this.state.recetas) {
                                        const recetas = this.state.recetas.map((element, index) => {
                                            return (
                                                <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.recetas_id.url.replace(/ /g, "-").toLowerCase()}`) }} className="contain-swip" >

                                                    <img alt={element.recetas_id.alt_imagen} style={{ width: "93%", height: "auto", marginLeft: "10px" }} src={process.env.REACT_APP_CMS_ASSETS + element.recetas_id.imagen} />

                                                    <div style={{ padding: "20px", fontFamily: "Avenir-Medium" }}>
                                                        <label style={{ fontFamily: "CocogooseProRegular", fontSize: "20px" }}>{element.recetas_id.nombre}</label><br />
                                                        <label style={{ fontFamily: "AvenirBook" }} ></label>{element.recetas_id.descripcion}
                                                        <br /> <br />
                                                        {/*<div style={{ display: "flex", fontFamily: "AvenirBook" }}>
                                                            <img src={timer} style={{ width: "20px", height: "20px" }} />{element.tiempo + "  " + element.personas}
                                                        </div>*/}
                                                    </div>
                                                </div>

                                            )

                                        })

                                        return (
                                            <Swiper {...paramsspace}>
                                                {recetas}
                                            </Swiper>
                                        )
                                    }


                                })()}
                            </div>
                            <button onClick={(e) => { window.scrollTo(0, 0); history.push("/recetas") }} style={{ margin: "30px" }} className=" btn btn-carousel menos">VER TODAS</button>
                        </div>
                    </div>




                    <div style={{ width: "100%", fontFamily: "Avenir" }}>

                        <TeSorprendemosMobile />

                        <div style={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "center", fontFamily: "avenir", padding: "20px", paddingTop: "50px" }}>
                            <div >
                                <Fade left> <p style={{ color: "#d41246", fontFamily: "AvenirBlack", fontSize: "15px" }}>RECETAS</p></Fade>

                                <Fade left> <label className="CocogooseProRegular" style={{ fontSize: "22px" }}><b>UN SINFÍN DE RECETAS  PENSADAS PARA TI Y TU FAMILIA </b></label><br /><br /></Fade>

                                <Fade left><label style={{ fontFamily: "AvenirBook", fontSize: "20PX" }}>Para saciar tu antojo, elige entre un sinfín de recetas que tenemos pensadas para consentirlos a ti y a tu familia.</label></Fade>

                            </div>
                            {(() => {
                                if (this.state.categorias) {


                                    const categorias = this.state.categorias.map((element, index) => {
                                        let varplatillo = null

                                        switch (element.nombre) {
                                            case "Carnes y Aves":
                                                varplatillo = avesycarnes
                                                break;
                                            case "Postres":
                                                varplatillo = postres
                                                break;
                                            case "Entradas":
                                                varplatillo = entradas
                                                break;
                                            case "Mariscos":
                                                varplatillo = mariscos
                                                break;
                                            case "Salsas":
                                                varplatillo = salsas
                                                break;
                                            case "Antojitos Mexicanos":

                                                varplatillo = antojitos
                                                break;
                                            case "Pastas y Sopas":
                                                varplatillo = syp
                                                break;
                                            case "Guarniciones":
                                                varplatillo = guarnicione
                                                break;
                                            case "Bebidas":
                                                varplatillo = bebidas
                                                break;

                                            default:

                                        }
                                        return (
                                            <Slide left>
                                                <div onClick={() => { window.scrollTo(0, 0); history.push(`/recetas/${element.url.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "-")}`) }} className="col-12 hover-content">
                                                    <img className="scale-class " style={{ maxWidth: "80%", height: "auto" }} src={varplatillo}></img>
                                                    <span style={{ fontFamily: "AvenirBlack", cursor: "pointer", fontSize: "20px", position: "absolute" }} className=" badge badge-pill badge-danger ">{element.nombre}</span>
                                                </div>
                                            </Slide>
                                        )
                                    })
                                    return categorias

                                }
                            })()}


                            <button onClick={() => { history.push("/recetas"); window.scrollTo(0, 0) }} style={{ marginTop: "25px" }} className=" btn btn-carousel menos-platillos-mobile">VER TODAS</button>
                        </div>
                    </div>


                </seccion>

            </>
        )

    }

}


const mapStateToProps = state => ({

    data: { ...state, categoriaTags: state.categoriaTags }
});



export default connect(mapStateToProps)(Home)
