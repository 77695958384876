import React, { Component } from 'react';
import './App.css';
import { Router as Router, Route, Switch } from 'react-router-dom';
import Home from './Components/Home'
import Header from './Components/Header/index'
import Footer from './Components/Footer/index'
import history from './history.js'
import MobileMenu from './Components/MobileMenu/index'
import { AppContextProvider } from './Components/appContext'
import DetalleProducto  from './Components/DetalleProducto/index'
import DetalleProductosCategoria from './Components/DetalleProductosCategoria'
import Receta from './Components/Receta/index'
import FoundPage from './Components/FoundPage/index'
import Productos from './Components/productos'
import Contacto from './Components/Contacto/index'
import Promociones from './Components/Promociones/index'
import DetalleReceta from './Components/DetalleReceta/index'
import Nosotros from './Components/Nosotro/index'
import Tips from './Components/Tips/index'
import Blog from './Components/Blog/index'
import DetoxSection from './Components/Detox/index'

import { Parallax } from 'react-scroll-parallax';
import Nosotro from './Components/Nosotro/index';
import { Helmet } from 'react-helmet';
class App extends Component {
  constructor() {
    super()
  this.state={

  }

  } 

  render() {
    const canonicalUrl = (`https://www.mccormick.mx` + (window.location.pathname === "/" ? "" : window.location.pathname)).split("?")[0];
    return (
      <AppContextProvider value={this.state}>
        <>
          <div  className="App">
            <Router history={history}>
              <Helmet>
                <link rel="canonical" href={canonicalUrl} />
              </Helmet>
              <Header></Header>
              <Switch>
                {
                  (() => {
                     //alert(window.location.pathname)
                    switch (window.location.pathname) {
                      case "/productos/t%C3%A9":
                      history.replace("/productos/tes-sobres")
                      break;
                      case "/productos/mostaza":
                        history.replace("/productos/mostaza-amarilla")
                        break;
                      case "/productos/t%C3%A9":
                        history.replace("/productos/tes-sobres")
                        break;
                        case "/productos/te":
                        history.replace("/productos/tes-sobres")
                        break;
                        case "/productos/té":
                        history.replace("/productos/tes-sobres")
                        break;
                      case "/productos/especias-y-condimentos/especias-del-mundo":
                        history.replace("/productos/especias-y-condimentos/del-mundo")
                        break;
                      case "/productos/especias-y-condimentos/especias-gourmet":
                        history.replace("/productos/especias-y-condimentos/gourmet")
                        break;
                      case "/productos/especias-y-condimentos/grill-mates":
                        history.replace("/productos/especias-y-condimentos/para-parrilla")
                        break;
                      case "/productos/especias-y-condimentos/ideales-para-tus-postres":
                        history.replace("/productos/especias-y-condimentos/dulces-postres")
                        break;
                      case "/productos/especias-y-condimentos/ajos-selección-especial":
                        history.replace("/productos/especias-y-condimentos/dulces-postres")
                        break;
                      case "/productos/especias-y-condimentos/ajos-selección-especial":
                        history.replace("/productos/especias-y-condimentos/dulces-postres")
                        break;
                      case "/recetas/salsas":
                        history.replace("/recetas/salsas-aderezos-vinagretas")
                        break;
                      default:
                      history.replace(window.location.pathname)
                    }
                  })()
                }
             
                <Route exact path="/" render={(props) => <Home {...props}   ></Home>} />
                <Route exact path="/receta/:post_id" render={(props) => <DetalleReceta {...props}></DetalleReceta>} />
                <Route exact path="/recetas/:post_id" component={(props) => <Receta {...props}></Receta>} />
                <Route exact path="/recetas" render={(props) => <Receta {...props}></Receta>} />
                <Route exact path="/historia" render={(props) => <Nosotros ></Nosotros>} />
                <Route exact path="/productos" render={(props) => <Productos ></Productos>} />
                <Route exact path="/productos/tes-sobres/plan-detox" component={(props) => <DetoxSection {...props}></DetoxSection>} />
                <Route exact path="/productos/tes-sobres/plan-detox/:tea" component={(props) => <DetoxSection {...props}></DetoxSection>} />
                <Route exact path="/detalle-producto" render={(props) => <DetalleProducto {...props}></DetalleProducto>} />
                <Route exact path="/productos/:post_id" render={(props) => <DetalleProductosCategoria {...props}></DetalleProductosCategoria>} />
                <Route exact path="/productos/:post_id/:post_sub_cat" component={(props) => <DetalleProductosCategoria {...props}></DetalleProductosCategoria>} />
                <Route exact path="/producto/:post_id" component={(props) => <DetalleProducto {...props}></DetalleProducto>} />
                {/* <Route exact path="/contacto" render={(props) => <Contacto></Contacto>} /> */}
                <Route exact path="/promociones" component={(props) => <Promociones></Promociones>} />
                <Route exact path="/promociones/:promo" render={(props) => <Promociones></Promociones>} />
                <Route exact path="/aviso-de-privacidad" component={Home} />
                <Route exact path="/terminos-y-condiciones" component={Home} />
                <Route exact path="/blog" component={Blog} />
                <Route exact path="/blog/:categoria" component={Blog} />
                <Route exact path="/blog/:categoria/:nota" component={Tips} />
                <Route path='*' exact={false} component={FoundPage} />
              </Switch>
              <Footer></Footer>
            </Router>
          </div>
        </>
      </AppContextProvider>
    );
  }
}


export default App;
