import React, { PureComponent } from 'react'
import { AppContextConsumer } from '../appContext'
import mccormick from './img/Logo_McCormick.png'
import history from '../../history'
import './index.css'
import '../Animate/index.css'
import lupa from '../Resource/iconos/lupa.svg'
import facebook from '../Resource/iconos/facebook.svg'
import youtube from '../Resource/iconos/youtube.png'
import instagram from '../Resource/iconos/instagram-logo.svg'
import { Modal } from 'react-bootstrap'
import Menu from '../MobileMenu/index'
import MenuImage from './img/menu.PNG'
import PropTypes from 'prop-types';
import BuscadorHeader from '../redux/actions/BuscadorHeader'
import { withStyles, withTheme } from '@material-ui/styles';
import Collapse from 'react-bootstrap/Collapse'
import { connect } from 'react-redux'
import ModalMC from '../ModalMC'
import der from '../Resource/iconos/arrow-slide-der.svg'
import isq from '../Resource/iconos/arrow-slide-izq.svg'
import axios from 'axios'
import Swiper from 'react-id-swiper';
import CloseCrossImg from './img/close.png'
import Tilt from 'react-parallax-tilt';
import TePuedeInteresar from '../TePuedeInteresar/index'

const styles = theme => ({
  root: {
    width: '100%',
    zIndex: 2,
    border: "0px solid rgb(255, 215, 102)",
    width: "-webkit-fill-available",
    position: "relative",
    top: "1%",
    boxShadow: "none"
  },
  detail: {
    width: "inherit",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
  },
  expansionPanelSummaryContent: {
    "& > :last-child": {
      transform: "rotate(0deg) !important"
    }
  },
  expansionPanelSummaryExpandIcon: {
    transform: "rotate(0deg) !important"
  }

});
class Header extends PureComponent {
  constructor() {
    super()
    this.collapse = React.createRef();
    this.state = {
      modal: false,
      Open: false,
      openHover: false,
      payload: "",
      modalMobil: false,
      movilMenu: false,
      hoverProductosData: null,
      categoriaRecetas: null,
      resultados: null,
      categorias: null,
      categoriasRelacionadas: []
    }
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleMovilMenu = this.handleMovilMenu.bind(this);
  }

  handleMovilMenu() {

    this.setState({
      movilMenu: !this.state.movilMenu
    })


  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        Open: false
      })
    }
  }



  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  onChange = async (value) => {

    this.setState({
      userInput: value

    });

    if (value.length >= 3) {
      const result = await axios.get(process.env.REACT_APP_API_URL_NEW + "/buscador?valor=" + value);
      this.props.dispatch({ type: "BUSCADOR", resultados: result.data });
      this.setState({
        resultados: this.props.data.BuscadorHeader.resultados
      })
    } else {
      this.setState({
        resultados: null
      })
    }

  }

  handleBuscadorMobil = () => {

    this.setState({
      modalMobil: !this.state.modalMobil
    })

  }

  handleBuscador = () => {
    const filtro = this.props.filtro.map((element, index) => {
      const data = element.productos.map((elemen, index) => {
        return (
          <li onClick={() => { window.scrollTo(0, 0); history.push("/detalle-producto") }} style={{ cursor: "pointer" }}>{elemen}</li>
        )
      })
      return (
        <>
          <label><b>{element.name}</b></label>
          <ul style={{ listStyleType: "none" }}>
            {data}
          </ul>
        </>
      )
    })
    this.setState({
      filtro
    })
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

    this.handleRutas()
    this.loadCatRecetas()

  }

  loadCatRecetas = async () => {


    if (this.state.categoriasReceta == null) {
      //Recetas por categorias
      const categoriaResult = await axios.get(process.env.REACT_APP_API_URL_NEW + "/categorias_recetas?fields=*.*.*");
      this.props.dispatch({ type: "OBTENER_RECETAS_POR_CATEGORIA", categoriaRecetas: categoriaResult.data.data });
      const result = await axios.get(process.env.REACT_APP_API_URL_NEW + "/categorias?fields=*.*,productos.subcategoria.*&filter[estatus]=published");
      this.props.dispatch({ type: "OBTENER_PRODUCTOS_POR_CATEGORIAS", categoriaProductos: result.data.data });
    }
    const categoriasRelacionadas = await axios.get(process.env.REACT_APP_API_URL_NEW + `/tags?fields=*.*,recetas.recetas_id.*&filter[nombre]=Te puede interesar`);
    // const result = await axios.get(process.env.REACT_APP_API_URL + "/categorias");
    // this.props.dispatch({ type: "OBTENER_PRODUCTOS_POR_CATEGORIAS", categoriaProductos: result.data });
    let array = []
    this.props.data.getproductsbycategory.categoriaProductos.map((element, index) => {

      const conteo = {
        "nombre": element.nombre,
        "conteo": element.productos.length,
        "url":element.url
      }
      array.push(conteo)
    })

    this.setState({
      categoriasRelacionadas: categoriasRelacionadas.data.data,
      hoverProductosData: array,
      categoriaRecetas: this.props.data.categoriaRecetas.categoriaRecetas,
      categorias: this.props.data.getproductsbycategory.categoriaProductos
    });

  }






  handleHome = () => {

    const productos = document.getElementById("productos")
    productos.style.borderBottom = "1px solid transparent"
    const recetas = document.getElementById("recetas")
    recetas.style.borderBottom = "1px solid transparent"
    const promociones = document.getElementById("promociones")
    promociones.style.borderBottom = "1px solid transparent"
    // const contacto = document.getElementById("contacto")
    // contacto.style.borderBottom = "1px solid transparent"
    const nosotros = document.getElementById("nosotros")
    nosotros.style.borderBottom = "1px solid transparent"
    const tips = document.getElementById("tips")
    tips.style.borderBottom = "1px solid transparent"

    const home = document.getElementById("home");
    home.style.borderBottom = "5px solid red";
    home.style.transition = ".3s";
    window.scrollTo(0, 0);
    history.push("/")
  }

  handleRutas = () => {



    switch (window.location.pathname) {
      case "/":
        const home = document.getElementById("home")

        home.style.borderBottom = "5px solid red"
        home.style.transition = ".3s"
        break;
      case "/productos":
        const productos = document.getElementById("productos")
        productos.style.borderBottom = "5px solid red"
        productos.style.transition = ".3s"
        break;
      case "/recetas":
        const recetas = document.getElementById("recetas")
        recetas.style.borderBottom = "5px solid red"
        recetas.style.transition = ".3s"
        break;
      case "/promociones":
        const promociones = document.getElementById("promociones")
        promociones.style.borderBottom = "5px solid red"
        promociones.style.transition = ".3s"
        break;
      // case "/contacto":
      //   const contacto = document.getElementById("contacto")
      //   contacto.style.borderBottom = "5px solid red"
      //   contacto.style.transition = ".3s"
      //   break;
      case "/historia":
        const nosotros = document.getElementById("nosotros")
        nosotros.style.borderBottom = "5px solid red"
        nosotros.style.transition = ".3s"
        break;
        case "/blog":
        const tips = document.getElementById("tips")
        tips.style.borderBottom = "5px solid red"
        tips.style.transition = ".3s"
        break;

      default:
        //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
        break;
    }
  }

  headerItems = (e) => {



    const items = document.getElementsByClassName("item---menu")
    var i;
    for (i = 0; i < items.length; i++) {
      items[i].style.borderBottom = "5px solid transparent"
    }

    e.target.style.borderBottom = "5px solid red"
    e.target.style.transition = ".3s"

  }

  render() {
    const paramsRecetasplatillo = {
      slidesPerView: 3,
      slidesPerGroup: 1,
      spaceBetween: 0,
      loop: true,
      autoplay: {
        delay: 3200,
        disableOnInteraction: false
      },
      loopFillGroupWithBlank: false,
    }
    const { classes } = this.props;

    return (
      <AppContextConsumer>
        {value =>
          <>
            <ModalMC handleBuscadorMobil={this.handleBuscadorMobil} show={this.state.modalMobil} />
            <div style={{ width: "100%", height: "54px" }} ></div>
            <nav className="menu-desk" >
              <div><img onClick={() => { this.handleHome() }} className='logo-header' src={mccormick}></img></div>
              <div style={{ display: "flex" }}>

                <div id="home" onClick={(e) => { window.scrollTo(0, 0); this.headerItems(e); history.push("/") }} className="item---menu">Home</div>
                <Collapse in={this.state.openHove}>
                  <div ref={this.collapse} className="col-6" onMouseOver={() => { this.setState({ openHove: true }) }} onMouseLeave={() => { this.setState({ openHove: false }) }} id="example-collapse-text" style={{ left: "7%", textAlign: "center", border: "1px solid rgba(0,0,0,.2)", borderRadius: ".3rem", padding: "15px", paddingTop: "0px", backgroundClip: "padding-box", top: "55px", position: "absolute", zIndex: "44444440", background: "#ffffff" }}>
                    <label style={{ color: "#686868" }} onClick={() => { window.location.href = (`/productos`) }} className="item-hover-product">VER TODOS</label><br />
                    {(() => {
                      if (this.state.hoverProductosData) {
                        return this.state.hoverProductosData.map((element, index) => {

                          return (
                            <>
                              <div style={{ display: "flex", justifyContent: "center", alignItems: "top" }}>
                                <label onClick={() => { window.location.href = (`/productos/${element.url.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "-").toLowerCase()}`) }} className="item-hover-product">{element.nombre + " "}</label><label style={{ fontSize: "16px" }}>{"   (" + element.conteo + ")"}</label><br />
                              </div>
                            </>
                          )
                        })
                      }
                    })()}
                  </div>
                </Collapse>
                <div id="productos" onMouseOver={() => { this.setState({ openHove: true }) }} onMouseLeave={(e) => { console.log(e.target); console.log(this.collapse.current.id); this.setState({ openHove: false }) }} onClick={(e) => { window.scrollTo(0, 0); this.headerItems(e); history.push("/productos") }} className="item---menu">Productos</div>
                <div id="recetas" onClick={(e) => { window.scrollTo(0, 0); this.headerItems(e); history.push(`/recetas`) }} className="item---menu">Recetas</div>
                <div id="promociones" onClick={(e) => { window.scrollTo(0, 0); this.headerItems(e); window.location.href=("/promociones") }} className="item---menu">Promociones</div>
                <div id="nosotros" onClick={(e) => { window.scrollTo(0, 0); this.headerItems(e); history.push("/historia") }} className="item---menu">Historia</div>
                {/* <div id="contacto" onClick={(e) => { window.scrollTo(0, 0); this.headerItems(e); history.push("/contacto") }} className="item---menu">Contacto</div> */}
                <div id="tips" onClick={(e) => { window.scrollTo(0, 0); this.headerItems(e); window.location.href=("/blog") }} className="item---menu">Blog</div>
              </div>
              <div ref={this.setWrapperRef} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60px" }}>
                <Collapse className="my-collapse" in={this.state.Open}>
                  <div id="example-collapse-text" style={{ border: "1px solid rgba(0,0,0,.2)", borderRadius: ".3rem", padding: "15px", backgroundClip: "padding-box", top: "55px", position: "absolute", zIndex: "44444440", background: "#ffffff", borderTop: "5px solid red" }}>
                    <div style={{ width: "100%",display:"flex" }}>
                          <img  src={lupa} style={{ padding: "4px", width: "25px", height: "25px", cursor: "pointer" }} />
                          <input autocomplete="off" aria-expanded={this.state.Open} aria-controls="example-collapse-text" name="payload" onChange={(e) => this.onChange(e.target.value)}  style={{ border: "0px", outline: "none", paddingLeft: "5px", paddingRight: "5px", fontSize: "13px", fontFamily: "AvenirBook",width:"100%" }} placeholder="Buscar"></input>
                          <img style={{cursor:"pointer",height:"20px",width:"20px"}} onClick={() => this.setState({ Open: false })} src={CloseCrossImg} />
                    </div>
                    {(() => {
                      if (this.state.resultados != null) {
                        const recetas = this.state.resultados.recetas.map((element, index) => {
                          if (element.estatus === "draft") {
                            return
                          }
                          return (
                            <>
                              <div className="col-3" onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.url.replace(/ /g, "-").toLowerCase()}`) }} style={{ display: "flex", position: "relative", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointe" }}>
                                <img alt={element.alt_imagen}  src={process.env.REACT_APP_CMS_ASSETS +element.imagen.id} style={{ position: "relativw", maxWidth: "100%", width: "100%", top: "5px" }}></img>
                                <label style={{ fontFamily: "AvenirBook" }} >{element.nombre}</label>
                              </div>
                            </>
                          )
                        })
                        const productos = this.state.resultados.productos.map((element, index) => {
                          if (element.estatus === "draft") {
                            return
                          }
                          return (
                            <>
                              <div className="col-3" onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.url.replace(/ /g, "-").toLowerCase()}`) }} style={{ display: "flex", position: "relative", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointe" }}>
                                <img alt={element.alt_imagen}  src={process.env.REACT_APP_CMS_ASSETS +element.imagen.id} style={{ position: "relativw", maxHeight: "80px", width: "auto", top: "5px" }}></img>
                                <label style={{ fontFamily: "AvenirBook" }}>{element.nombre}</label>
                              </div>
                            </>
                          )
                        })


                        return (
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "top", flexDirection: "column" }}>

                            {productos.length > 0 ? <label style={{ fontFamily: "AvenirBlack" }} >Productos</label> :  <label style={{ fontSize: "1vw", marginTop: "3px" }} className="CocogooseProRegular">Oops, parece que todavía no tenemos ese sabor, ¡gracias por la sugerencia!</label>}
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexWrap: "wrap", height: "250px", overflowY: "auto", overflowX: "hidden" }}>
                              {productos.length > 0 ? productos :
                              <>
                                {(() => {

                                  if (this.state.categorias != null) {

                                    const cat = this.state.categorias.filter((element, index) => {
                                      if (element.nombre === this.state.categoriName) {

                                      } else {
                                        return element
                                      }
                                    })



                                    const productos = []
                                    cat.filter((element, index) => {
                                      element.productos.map((element, index) => {
                                        if (element.estrella) {
                                          productos.push(element)
                                        }
                                      })
                                    })

                                    if (productos.length === 3) {
                                      this.setState({
                                        swiperFlechas: false
                                      })
                                    }


                                    const prod = productos.map((element, index) => {
                                      if (element.estatus === "draft") {
                                        return
                                      }
                                      return (
                                        <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.url.replace(/ /g, "-").toLowerCase()}`) }} className="col-4" style={{ position: "relative" }} >
                                          <Tilt
                                            className="parallax-effect-img"
                                            tiltMaxAngleX={10}
                                            tiltMaxAngleY={10}
                                            perspective={500}
                                            transitionSpeed={1500}
                                            scale={1}
                                            gyroscope={true}
                                          >
                                            <img  alt={element.alt_imagenFondo}  src={process.env.REACT_APP_CMS_ASSETS +element.imagenFondo} style={{ maxWidth: "100%", height: "auto", }} />
                                          </Tilt>
                                          <img alt={element.alt_imagen}  src={process.env.REACT_APP_CMS_ASSETS +element.imagen} style={{ maxWidth: "50%", maxHeight: "120px", position: "absolute", top: "10%", zIndex: "1", left: "25%" }} />
                                          <div style={{ marginTop: "3%", textAlign: "center" }}>
                                            <b style={{ fontSize: "13px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
                                            <label style={{ fontSize: "13px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />
                                          </div>
                                        </div>
                                      )


                                    })

                                    return (
                                      <>


                                        <Swiper {...paramsRecetasplatillo}>
                                          {prod}
                                        </Swiper>

                                      </>
                                    )


                                  }

                                })()}
                                </>
                              }

                            </div>

                            {recetas.length > 0 ? <label style={{ fontFamily: "AvenirBlack" }} >Recetas</label> :  <label style={{ fontSize: "1vw", marginTop: "3px" }} className="CocogooseProRegular">Oops, parece que todavía no tenemos ese sabor, ¡gracias por la sugerencia!</label>}
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexWrap: "wrap", height: "250px", overflowY: "auto", overflowX: "hidden" }}>
                              {recetas.length > 0 ? recetas :
                              <>
                                {(() => {
                                    if (this.state.categoriasRelacionadas.length > 0) {

                                      const recetas = this.state.categoriasRelacionadas.map((element, index) => {
                                        return element.recetas.map((element, index) => {
                                          if (element.estatus === "draft") {
                                            return
                                          }
                                          return (
                                            <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.recetas_id.url.replace(/ /g, "-").toLowerCase()}`) }} style={{ border: "0px solid transparent", cursor: "pointer" }} className="card col-4" >
                                              <div style={{ overflow: "hidden" }}>

                                                <img alt={element.recetas_id.alt_imagen}  className="recetas-anim" style={{ width: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS +element.recetas_id.imagen} />

                                              </div>
                                              <div style={{ width: "100%", fontFamily: "AvenirNextLTProMedium", marginTop: "23px", marginBottom: "23px" }}>
                                                <label style={{ fontFamily: "CocogooseProRegular", fontSize: "13px" }}>{element.recetas_id.nombre}</label><br />

                                                <label style={{ fontFamily: "AvenirBook", fontSize: "13px" }} >{element.recetas_id.descripcion}</label>

                                                <br /> <br />
                                              </div>
                                            </div>
                                          )
                                        }
                                        )


                                      })
                                      return (
                                        <>
                                          <Swiper {...paramsRecetasplatillo}>
                                            {/* {recetas} */}
                                          </Swiper>

                                        </>

                                      )
                                    }

                                  })()}
                              </>
                              }
                            </div>
                          </div>
                        )



                      } else {
                        return (
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "top", flexDirection: "column" }}>
                            <div style={{ width: "100%", marginTop: "5px", marginBottom: "5px" }} >

                              <label style={{ fontSize: "1vw", marginTop: "3px" }} className="CocogooseProRegular">Oops, parece que todavía no tenemos ese sabor, ¡gracias por la sugerencia!</label><br />
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexWrap: "wrap", height: "245px", overflowY: "auto", overflowX: "hidden" }}>
                              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#ffffff", width: "100%" }}>
                                <div className="col-12" style={{ width: "100%", display: "flex", flexWrap: "wrap", fontFamily: "Avenir" }}>
                                  {(() => {
                                    if (this.state.categorias != null) {
                                      const cat = this.state.categorias.filter((element, index) => {
                                        if (element.nombre === this.state.categoriName) {
                                        } else {
                                          return element
                                        }
                                      })
                                      const productos = []
                                      cat.filter((element, index) => {
                                        element.productos.map((element, index) => {
                                          if (element.estrella) {
                                            productos.push(element)
                                          }
                                        })
                                      })
                                      if (productos.length === 3) {
                                        this.setState({
                                          swiperFlechas: false
                                        })
                                      }
                                      const prod = productos.map((element, index) => {
                                        if (element.estatus === "draft") {
                                          return
                                        }
                                        return (
                                          <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/producto/${element.url.replace(/ /g, "-").toLowerCase()}`) }} className="col-4" style={{ position: "relative" }} >
                                            <Tilt
                                              className="parallax-effect-img"
                                              tiltMaxAngleX={10}
                                              tiltMaxAngleY={10}
                                              perspective={500}
                                              transitionSpeed={1500}
                                              scale={1}
                                              gyroscope={true}
                                            >
                                              <img alt={element.alt_imagenFondo} src={process.env.REACT_APP_CMS_ASSETS +element.imagenFondo} style={{ maxWidth: "100%", height: "auto", }} />
                                            </Tilt>
                                            <img alt={element.alt_imagen} src={process.env.REACT_APP_CMS_ASSETS +element.imagen} style={{ maxWidth: "50%", maxHeight: "120px", position: "absolute", top: "10%", zIndex: "1", left: "25%" }} />
                                            <div style={{ marginTop: "3%", textAlign: "center" }}>
                                              <b style={{ fontSize: "13px", fontFamily: "AvenirBlack" }} className="CocogooseProRegular mt-5">{element.nombre}</b><br />
                                              <label style={{ fontSize: "13px", fontFamily: "AvenirBook" }} >{element.descripcion}</label><br />
                                            </div>
                                          </div>
                                        )
                                      })
                                      return (
                                        <>
                                          <Swiper {...paramsRecetasplatillo}>
                                            {prod}
                                          </Swiper>

                                        </>
                                      )
                                    }
                                  })()}
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "95%", paddingLeft: "15px", marginTop: "30px" }} >
                              <label style={{ fontSize: "1vw", marginTop: "3px" }} className="CocogooseProRegular">Oops, parece que todavía no tenemos ese sabor, ¡gracias por la sugerencia!</label><br />
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexWrap: "wrap", height: "245px", marginTop: "10px", overflowY: "auto", overflowX: "hidden" }}>
                              <div style={{ width: "95%", margin: "0 auto", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "#ffffff", position: "relative", zIndex: "1" }}>
                                <div style={{ width: "95%", display: "flex", flexWrap: "wrap", fontFamily: "Avenir", justifyContent: "center", alignItems: "center" }}>
                                  {(() => {
                                    if (this.state.categoriasRelacionadas.length > 0) {
                                      const recetas = this.state.categoriasRelacionadas.map((element, index) => {
                                        return element.recetas.map((element, index) => {
                                          if (element.estatus === "draft") {
                                            return
                                          }
                                          return (
                                            <div onClick={() => { window.scrollTo(0, 0); window.location.href = (`/receta/${element.recetas_id.url.replace(/ /g, "-").toLowerCase()}`) }} style={{ border: "0px solid transparent", cursor: "pointer" }} className="card col-4" >
                                              <div style={{ overflow: "hidden" }}>
                                                <img alt={element.recetas_id.alt_imagen}  className="recetas-anim" style={{ width: "100%", height: "auto" }} src={process.env.REACT_APP_CMS_ASSETS +element.recetas_id.imagen} />
                                              </div>
                                              <div style={{ width: "100%", fontFamily: "AvenirNextLTProMedium", marginTop: "23px", marginBottom: "23px" }}>
                                                <label style={{ fontFamily: "CocogooseProRegular", fontSize: "13px" }}>{element.recetas_id.nombre}</label><br />
                                                <label style={{ fontFamily: "AvenirBook", fontSize: "13px" }} >{element.recetas_id.descripcion}</label>
                                                <br /> <br />
                                              </div>
                                            </div>
                                          )
                                        }
                                        )
                                      })
                                      return (
                                        <>
                                          <Swiper {...paramsRecetasplatillo}>
                                            {recetas}
                                          </Swiper>
                                        </>
                                      )
                                    }
                                  })()}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })()}
                  </div>
                </Collapse>
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img onClick={(e) => { this.setState({ Open: !this.state.Open }) }} src={lupa} style={{ padding: "5px", width: "31px", height: "31px", cursor: "pointer" }} />
                <div style={{ width: "2px", background: "gray", height: "27px" }} />
                <img onClick={() => { window.open("https://www.facebook.com/McCormickMexico", "_blank") }} src={facebook} style={{ width: "21px", height: "21px", marginRight: "15px", marginRight: "10px", marginLeft: "10px", cursor: "pointer" }} />
                <img onClick={() => { window.open("https://www.instagram.com/mccormick_mx/?hl=es-la", "_blank") }} src={instagram} style={{ width: "21px", height: "21px", marginRight: "15px", marginRight: "10px", marginLeft: "10px", cursor: "pointer" }} />
                <img onClick={() => { window.open("https://www.youtube.com/user/McCORMICKMX", "_blank") }} src={youtube} style={{ width: "21px", height: "21px", marginRight: "15px", marginRight: "10px", marginLeft: "10px", cursor: "pointer" }} />
              </div>
            </nav>
            <nav className="menu-mobile">
              <div>
                <img onClick={() => { this.handleHome() }} className='logo-header' src={mccormick}></img>
              </div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px" }}>
                  <img onClick={(e) => { this.handleBuscadorMobil() }} src={lupa} style={{ padding: "4px", width: "23px", height: "24px", cursor: "pointer" }} />
                </div>
                <div style={{ width: "2px", background: "#e6e6e6", height: "60%" }} />
                <img onClick={() => { this.handleMovilMenu() }} src={MenuImage} style={{ width: "21px", height: "21px", marginRight: "10px", marginLeft: "10px", cursor: "pointer" }} />
                <Menu showMenu={this.state.movilMenu} productos={this.state.hoverProductosData} catrecetas={this.state.categoriaRecetas} ></Menu>
              </div>
            </nav>
            <Modal show={this.props.modal} onHide={() => this.props.HandleModal(false)}>
              <Modal.Header className="modal-header" closeButton>
                <div className="input-group ">
                  <input type="text" style={{ borderRadius: "15px 0px 0px 15px", border: "1px", outline: "none" }} placeholder="Recipient's username" className="input-contacto" />
                  <div className="input-group-append">
                    <button type="button" style={{ borderRadius: "0px 15px 15px 0px", border: "1px", outline: "none" }}>
                      <img onClick={() => { this.props.HandleModal(true) }} src={lupa} style={{ width: "21px", height: "21px", marginRight: "10px", marginLeft: "10px", cursor: "pointer" }} />
                    </button>
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body className="body-modal">
              </Modal.Body>
              <Modal.Footer className="modal-footer">
                <button onClick={() => { this.props.HandleModal(false) }} className="btn-carousel" >Close</button>
              </Modal.Footer>
            </Modal>
          </>
        }
      </AppContextConsumer>
    )
  }
}








const mapStateToProps = state => ({
  modal: state.modal,
  menu: state.menu,
  filtro: state.BuscadorHeader,
  data: { ...state, categoriaProductos: state.categoriaProductos }
});


Header.propTypes = {
  classes: PropTypes.object.isRequired,
};


const header = withStyles(styles, { withTheme: true })(Header);

export default connect(mapStateToProps)(header)