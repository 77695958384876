import { slide as Menu } from 'react-burger-menu'
import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import {connect} from 'react-redux'
import closeIcon from './img/shape.svg'
import MenuController from '../redux/actions/MenuController'
import ExpancionPanel from '../MaterialUiCompnents/ExpancionPanel'
import MetisMenu from 'react-metismenu';
import RouterLink from 'react-metismenu-router-link'

import history from '../../history'
import './index.css'
import './mm.css'


 class MobileMenu extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      controlclose: false

    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
        console.log("--------PRODUCTOS----")
    console.log(this.props.productos)
    console.log("---------------------")

    this.setState(
      {
        controlclose: !this.state.controlclose
      }
    )
  }

  componentDidMount(){
  
  }

  render() {
    let productos = []
    let catrecetas = []
    if(this.props.productos !==null){
     productos = this.props.productos.map((element,key) =>{
      return(
          {
            icon: 'icon-class-name',
            label: element.nombre + " ("+ element.conteo + ")",
            to: "/productos/"+ element.url
          }
      )
    });}

    productos.unshift({
      icon: 'icon-class-name',
      label: "VER TODOS",
      to: "/productos"
    })

    if(this.props.catrecetas !== null){
      catrecetas = this.props.catrecetas.map((element,key) =>{
        let link = element.nombre.replace(/ /g, "-").toLowerCase()
        return(
          {
            icon: 'icon-class-name',
            label: element.nombre,
            to: "/recetas/" + link
          }
          
        );
      })
    }

    catrecetas.unshift({
      icon: 'icon-class-name',
      label: "Todas",
      to: "/recetas"
    })
    
    const content=[
      {
          icon: 'icon-class-name',
          label: 'HOME',
          to: '/',
      },
      {
          icon: 'icon-class-name',
          label: 'PRODUCTOS',
          content: productos 
      },
      {
        icon: 'icon-class-name',
        label: 'RECETAS',
        content: catrecetas,
    },
    {
      icon: 'icon-class-name',
      label: 'PROMOCIONES',
      to: '/promociones',
    },
    {
      icon: 'icon-class-name',
      label: 'HISTORIA',
      to: '/historia',
    },
  //   {
  //     icon: 'icon-class-name',
  //     label: 'CONTACTO',
  //     to: '/contacto',
  //  },
   {
    icon: 'icon-class-name',
    label: 'BLOG',
    to: '/blog',
 },

  ];



    return (

      <div  id="outer-container-movil">

        <Menu
          right
          isOpen={(this.props.showMenu === !this.state.controlclose)}
          customBurgerIcon={false}
        >
          <img onClick={this.handleClose} className='tam-closeIcon' src={closeIcon}></img>
          
          <MetisMenu content={content}  />
        </Menu>


      </div>



    );
  }
}


export default MobileMenu